import "./css/Home.css";
import ShowBlogPost from "./services/ShowBlogPost";
import ShowNewestBlogPost from "./services/ShowNewestBlogPost";

export function Home() {
  return <div className="home">
    <div className="newestPost">
      <h1>Seneste Nyt</h1>
    <ShowNewestBlogPost />
    </div>
    <div className="allPost">
      <ShowBlogPost />

    </div>
  </div>;
}
