// LocalFiles.js
import React, { useState, useEffect } from 'react';

import { endpoint } from '../../endPoint';
import axios from 'axios';

const DeleteFiles = () => {
  const [pdfFiles, setPdfFiles] = useState([]);

  useEffect(() => {
    const fetchPdfFiles = async () => {
      try {
        const response = await fetch(`${endpoint}/api/filer/getFiles`);
        const files = await response.json();

        // Add the prefix and remove the dot
        const formattedFiles = files.map((filename) => ({
          originalName: filename,
          displayName: filename.replace(/^\.\w+\s?/, ''), // Remove the dot and whitespace
          imagePath: `${endpoint}/Images/pdf-logo.png`, // Replace with the correct path
        }));

        setPdfFiles(formattedFiles);
      } catch (error) {
        console.error('Error fetching PDF files:', error);
      }
    };

    fetchPdfFiles();
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  const handleDelete = (filename) => {
    // Ask for confirmation before proceeding with the deletion
    const isConfirmed = window.confirm(`Are you sure you want to delete ${filename.displayName}?`);
  
    if (isConfirmed) {
      const filePath = `${endpoint}/api/filer/deleteFile/${filename.originalName}`;
  
      // Make a DELETE request using Axios
      axios.delete(filePath)
        .then(response => {
          // Handle success
          console.log('File deleted successfully:', response.data);
          // You may want to update your UI or perform additional actions here
  
          // Reload the site after successful deletion
          window.location.reload();
        })
        .catch(error => {
          // Handle error
          console.error('Error deleting file:', error);
          // You may want to show an error message or perform additional error handling here
        });
    }
  };

  

  return (
    <div className='local-files'>
      <h2>Liste af filer som er uploadet</h2>
      <p>Klik på den fil du ønsker at slette</p>
      <ul className="pdf-list">
        {pdfFiles.map((file, index) => (
          <li key={index} onClick={() => handleDelete(file)}>
            <img src="/Images/pdf-logo.png" alt="PDF Logo" className="pdf-logo" />
            {file.displayName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DeleteFiles;
