import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { endpoint } from '../../endPoint';
import "./CSS/ContactForm.css"

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    emne: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${endpoint}/api/contact/send`, formData);
      console.log('Email sent successfully');

      // Clear the form after successful submission
      setFormData({
        name: '',
        email: '',
        emne: '',
        message: '',
      });
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div>
      <h2>Kontakt</h2>
      <form onSubmit={handleSubmit} className=''>
        <TextField
          label="Navn"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Emne"
          type="text"
          name="emne"
          value={formData.emne}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Bedsked"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
