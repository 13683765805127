import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios"; // Import axios
import { endpoint } from "../../endPoint";
import "./css/NewPost.css";
import { AdminNav } from "./AdminNav";


export function NewPost() {
    const [formData, setFormData] = useState({
    titel: "",
    content: "",
    timestamp: ""
    
  });


  const handleInputChange = (field) => (event) => {
    const currentTimestamp = new Date().toISOString();
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      timestamp: currentTimestamp,
      [field]: event.target.value,
    }));
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Set the current timestamp
      const currentTimestamp = new Date().toISOString();
  
      // Update formData with the current timestamp
      setFormData({
        ...formData,
        timestamp: currentTimestamp,
      });
  
      // Make the API call
      const url = `${endpoint}/api/news`;
      await axios.post(url, formData);
  
      // Reset form after successful submission
      setFormData({
        titel: "",
        content: "",
        timestamp: "",
      });
  
      // Optionally, you can perform additional actions after successful submission
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div className="new-posts">
              <AdminNav />
    
    <div className="NewPost">
  
      <h1 className="login-text">Ny Nyhed</h1>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit} // Call handleSubmit on form submission
      >
        <TextField
          id="standard-basic"
          label="Titel"
          variant="standard"
          value={formData.titel}
          onChange={handleInputChange("titel")}
        />
         <TextField
          id="multiline-basic"
          label="Indhold"
          multiline
          rows={20} // Adjust the number of rows as needed
          variant="standard"
          value={formData.content}
          onChange={handleInputChange("content")}
        />
        <Button
          variant="contained"
          className="custom-button"
          type="submit"
        >
          Registrer
        </Button>
      </Box>
    </div>
    </div>
  );
}
