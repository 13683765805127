import React from "react";
import { NavLink } from "react-router-dom";
import "./css/Navbar.css"; // Import the CSS file


export function Navbar() {
  return (
    <nav className="navbar">
    <NavLink to="/">
      <img
        className="logo"
        src="/Images/logo-transparent-png.png"
        alt="logo"
      />
    </NavLink>
      <NavLink to="/Om-Os" className="nav-link">
        Personerne bag
      </NavLink>
      <NavLink to="/om-projektet" className="nav-link">
        Om Projektet
      </NavLink>
      <NavLink to="/Kontakt" className="nav-link">
        Kontakt
      </NavLink>
      <NavLink to="/login" className="nav-link"></NavLink>
    </nav>
  );
}
