import React, { useEffect, useState, Fragment } from "react";
import axios from 'axios';
import { endpoint } from "../../endPoint";
import "./CSS/ShowNewstBlogPost.css"

const ShowNewestBlogPost = () => {
  const [comments, setComments] = useState("");

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    const res = await axios.get(`${endpoint}/api/news/newest`);
    setComments(res.data);
  };

  const formatTimestamp = (timestamp) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return new Date(timestamp).toLocaleString('en-US', options);
  };

  const findLinksInContent = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = content.match(urlRegex);
    return matches || [];
  };

  if (!comments) {
    return null; // or loading indicator or some default content
  }

  const linksInContent = findLinksInContent(comments.content);

  const renderedComments = (
    <div key={comments.titel} className="new-blog-post">
      <h2 className="blog-post-title">{comments.titel}</h2>
      <p className="blog-post-content">
        {linksInContent.length > 0 ? (
          <>
            {comments.content.split(findLinksInContent(comments.content)).map((text, index) => (
              <Fragment key={index}>
                {index > 0 && (
                  <a href={linksInContent[index - 1]} target="_blank" rel="noopener noreferrer">
                    {linksInContent[index - 1]}
                  </a>
                )}
                {text}
              </Fragment>
            ))}
          </>
        ) : (
          comments.content
        )}
      </p>
      <p className="blog-post-timestamp">{formatTimestamp(comments.timeStamp).toLocaleString()}</p>
    </div>
  );

  return <div className="blog-posts-container">{renderedComments}</div>;
};

export default ShowNewestBlogPost;
