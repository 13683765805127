import { AdminNav } from "./AdminNav";
import "./css/AdminSite.css"

export function AdminSite(){


    return(
        <div>
        <AdminNav />
        <div className="admin-site">

            <h1>Admin side</h1>
            <p>Her kan oprettes nye nyheder som vises, på hovedsiden</p>
            <p>Der er mulighed for at opdatere i gamle nyheder, bl.a. hvis der er sket en stavefejl</p>
            <p>Her kan også sendes emailblast ud</p>
            <h3>Husk at logge ud</h3>
        </div>
        </div>
    )
}