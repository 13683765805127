import React, { useEffect, useState } from "react";
import axios from 'axios';
import { endpoint } from "../../endPoint";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import { AdminNav } from "./AdminNav";

const ShowAdminNews = () => {
  const [comments, setComments] = useState([]);
  const [editingComment, setEditingComment] = useState(null);
  const [editedTitel, setEditedTitel] = useState("");
  const [editedContent, setEditedContent] = useState("");

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    const res = await axios.get(`${endpoint}/api/news`);
    const sortedComments = res.data.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
    setComments(sortedComments);
  };

  const handleEditClick = (comment) => {
    setEditingComment(comment);
    setEditedTitel(comment.titel);
    setEditedContent(comment.content);
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
    setEditedTitel("");
    setEditedContent("");
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`${endpoint}/api/News/update/${editingComment.id}`, {
        titel: editedTitel,
        content: editedContent,
      });
      fetchComments(); // Refresh the comments after edit
      setEditingComment(null);
      setEditedTitel("");
      setEditedContent("");
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const renderedComments = comments.map((comment) => (
    <div key={comment.id} className="blog-post">
      <h2 className="blog-post-title">{comment.titel}</h2>
      {editingComment === comment ? (
        <>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={editedTitel}
            onChange={(e) => setEditedTitel(e.target.value)}
          />
          <TextareaAutosize
            placeholder="Content"
            minRows={20}
            style={{ width: "100%" }}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <Button onClick={handleSaveEdit} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleCancelEdit} variant="contained" color="secondary">
            Cancel
          </Button>
        </>
      ) : (
        <>
          <p className="blog-post-content">{comment.content}</p>
          <p className="blog-post-timestamp">{new Date(comment.timeStamp).toLocaleString()}</p>
          <Button onClick={() => handleEditClick(comment)} variant="contained" color="primary">
            Edit
          </Button>
        </>
      )}
    </div>
  ));

  return (
    <div className="ShowAdminNews">
        <AdminNav />
            <div className="blog-posts-container">{renderedComments}</div>
    </div>
  );
};

export default ShowAdminNews;
