import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios"; // Import axios
import { endpoint } from "../../endPoint";
import { useNavigate } from "react-router-dom";
import "../SmallComponents/css/SignUpEmail.css";
import "./css/Login.css"

export function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  const navigate = useNavigate();

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `${endpoint}/api/User/login`;
      const response = await axios.post(url, formData);

      if (response.status === 200) {
        const token = response.data.jwt; // Assuming the token is available in response.data
        localStorage.setItem("token", token);
        console.log("Login successful!!");
        navigate("/adminside");
      }

      // Reset form after submission, regardless of success or failure
      setFormData({
        username: "",
        password: ""
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Login failed. Please try again.");
    }
  };

  return (
    <div className="loginForm">
    <div className="SignUpEmail">
      <h1 className="login-text">LOGIN</h1>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit} // Call handleSubmit on form submission
      >
        <TextField
          id="email-input" // Changed the id to make it unique
          label="Email"
          variant="standard"
          value={formData.username}
          onChange={handleInputChange("username")}
        />
        <TextField
          id="password-input"
          label="Password"
          variant="filled"
          type="password"
          value={formData.password}
          onChange={handleInputChange("password")}
        />
        <Button
          variant="contained"
          className="custom-button"
          type="submit"
        >
          Login
        </Button>
      </Box>
    </div>
    </div>
  );
}
