import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import './css/AdminNav.css';

export function AdminNav() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear localStorage upon logout
    localStorage.clear();
    navigate("/");
    // You may also redirect the user to the login page or perform additional logout logic
  };

  // Check if JWT token is present in local storage
  const isTokenPresent = !!localStorage.getItem("token");

  return (
    <nav className="admin_nav_link">
      {isTokenPresent && <NavLink to="/adminside">Admin side</NavLink>}
      {isTokenPresent && <NavLink to="/opdaterenyheder">Redigere i Nyheder</NavLink>}
      {isTokenPresent && <NavLink to="/nynyhed">Skriv Ny Nyhed</NavLink>}
      {isTokenPresent && <NavLink to="/uploadfiler">Upload filer</NavLink>}
      {isTokenPresent && (
        <span onClick={handleLogout} className="logout-link">
          Logout
        </span>
      )}
    </nav>
  );
}
