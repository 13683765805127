// LocalFiles.js
import React, { useState, useEffect } from 'react';
import './css/LocalFiles.css';
import { endpoint } from '../../endPoint';

const LocalFiles = () => {
  const [pdfFiles, setPdfFiles] = useState([]);

  useEffect(() => {
    const fetchPdfFiles = async () => {
      try {
        const response = await fetch(`${endpoint}/api/filer/getFiles`);
        const files = await response.json();

        // Add the prefix and remove the dot
        const formattedFiles = files.map((filename) => ({
          originalName: filename,
          displayName: filename.replace(/^\.\w+\s?/, ''), // Remove the dot and whitespace
          imagePath: `${endpoint}/Images/pdf-logo.png`, // Replace with the correct path
        }));
        console.log(response);
        setPdfFiles(formattedFiles);
      } catch (error) {
        console.error('Error fetching PDF files:', error);
      }
    };

    fetchPdfFiles();
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  const handleDownload = (filename) => {
    const filePath = `${endpoint}/api/filer/downloadFile/${filename.originalName}`;
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filename.originalName;
    link.click();
  };

  return (
    <div className='local-files'>
      <h2>Dokumenter til download</h2>
      <ul className="pdf-list">
        {pdfFiles.map((file, index) => (
          <li key={index} onClick={() => handleDownload(file)}>
            <img src="/Images/pdf-logo.png" alt="PDF Logo" className="pdf-logo" />
            {file.displayName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocalFiles;
