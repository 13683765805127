// FileUpload.js

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { AdminNav } from './AdminNav';
import { endpoint } from '../../endPoint';
import './css/FileUpload.css'; // Import the CSS file
import DeleteFiles from './DeleteFile';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    axios.post(`${endpoint}/api/filer/upload`, formData)
      .then((response) => {
        console.log(response.data);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setSelectedFile(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="upload">     <AdminNav />
    <div className='file-upload'>
      <div  className="FileUpload">
        <input type="file" onChange={onFileChange} ref={fileInputRef} />
        <button onClick={onFileUpload}>Upload</button>
      </div>
        <DeleteFiles />
    </div>
    </div>
  );
};

export default FileUpload;
