import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./Components/Home";
import { OmOs } from "./Components/Sites/OmOs";
import { Kotankt } from "./Components/Sites/Kontakt";
import { Navigation } from "./Components/Navigation/Navigation";
import { Login } from "./Components/admin/Login"
import { Footer } from "./Components/Footer";
import { AdminSite } from "./Components/admin/AdminSite";
import ShowAdminNews from "./Components/admin/ShowAdminNews";
import { NewPost } from "./Components/admin/NewPost";
import FileUpload from "./Components/admin/FileUpload";
import { AboutProject } from "./Components/AboutProject";


function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Om-os" element={<OmOs />} />
          <Route path="/Kontakt" element={<Kotankt />} />
          <Route path="/om-projektet" element={<AboutProject />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adminside" element={<AdminSite /> } />
          <Route path="/nynyhed" element={<NewPost /> } />
          <Route path="/opdaterenyheder" element={<ShowAdminNews />} />
          <Route path="/uploadfiler" element={<FileUpload />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
