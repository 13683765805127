import ContactForm from "../services/ContactForm";
import "../css/Kontakt.css"

export function Kotankt() {
  return (
    <div className="Kontakt">
      <ContactForm />
    </div>
  );
}
