import { Navbar } from "./Navbar"
import { VideoScreen } from "./VideoScreen";
import { SignUpEmail } from "../SmallComponents/SignUpEmail"
import "./css/Navigation.css"

export function Navigation(){

   return (
    <div className="Navigation">
        <VideoScreen />
            <div className="navbar-overlay">
            <Navbar />
            <div className="smallComponents">
                <div className="emailSignup">
                    <SignUpEmail />
                </div>
            </div>
        </div>
    </div>
    )
}