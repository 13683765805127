import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Tos } from "../SmallComponents/ToS"; // Import your Tos component
import axios from "axios"; // Import axios
import { endpoint } from "../../endPoint";
import "./css/SignUpEmail.css";

export function SignUpEmail() {
  const [isChecked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `${endpoint}/api/EmailList`;
      await axios.post(url, formData);

      // Reset form after successful submission
      setFormData({
        email: "",
        firstName: "",
        lastName: "",
      });

      // Optionally, you can perform additional actions after successful submission
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="SignUpEmail">
      <h1 className="login-text">Tilmeld Nyhedsbrev</h1>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit} // Call handleSubmit on form submission
      >
        <TextField
          id="standard-basic"
          label="Fornavn"
          variant="standard"
          value={formData.firstName}
          onChange={handleInputChange("firstName")}
        />
        <TextField
          id="standard-basic"
          label="Efternavn"
          variant="standard"
          value={formData.lastName}
          onChange={handleInputChange("lastName")}
        />
        <TextField
          id="standard-basic"
          label="Email"
          variant="standard"
          value={formData.email}
          onChange={handleInputChange("email")}
        />
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
          label={
            <span>
              Jeg accepterer betingelserne{" "}
              <Button onClick={handleOpen} color="primary" size="small">
                Se vilkår
              </Button>
            </span>
          }
        />
        <Button
          variant="contained"
          className="custom-button"
          type="submit"
          disabled={!isChecked}
        >
          Registrer
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Vilkår for Tilmelding til Nyhedsbrev</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Tos />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
