import React, { useEffect, useState, Fragment } from "react";
import axios from 'axios';
import { endpoint } from "../../endPoint";
import "./CSS/ShowBlogPost.css";

const ShowBlogPost = () => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    const res = await axios.get(`${endpoint}/api/news`);
    setComments(res.data);
  };

  const formatTimestamp = (timestamp) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return new Date(timestamp).toLocaleString('en-US', options);
  };

  const findLinksInContent = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = content.match(urlRegex);
    return matches || [];
  };

  const renderedComments = Object.values(comments).map((comment) => {
    const linksInContent = findLinksInContent(comment.content);

    return (
      <div key={comment.titel} className="blog-post">
        <h2 className="blog-post-title">{comment.titel}</h2>
        <p className="blog-post-content">
          {linksInContent.length > 0 ? (
            <>
              {comment.content.split(findLinksInContent(comment.content)).map((text, index) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <a href={linksInContent[index - 1]} target="_blank" rel="noopener noreferrer">
                      {linksInContent[index - 1]}
                    </a>
                  )}
                  {text}
                </Fragment>
              ))}
            </>
          ) : (
            comment.content
          )}
        </p>
        <p className="blog-post-timestamp">{formatTimestamp(comment.timeStamp)}</p>
      </div>
    );
  });

  return <div className="blog-posts-container">{renderedComments}</div>;
};

export default ShowBlogPost;
