

export function Tos(){

    return(
        <div className="tos-info">
 
<p>Velkommen til Det Demokratiske Vindmølle Projekt nyhedsbrevstjeneste. Ved at tilmelde dig vores nyhedsbrev accepterer du følgende vilkår:</p>

<h4>Tilmelding og Accept:</h4>
<p>Ved at tilmelde dig vores nyhedsbrev bekræfter du, at du er indforstået med og accepterer disse brugsbetingelser.</p>

<h4>Personlige Oplysninger:</h4>
<p>Vi indsamler og opbevarer de personlige oplysninger, du afgiver ved tilmelding til nyhedsbrevet. Disse oplysninger vil blive behandlet i overensstemmelse med vores fortrolighedspolitik.</p>

<h4>Formål:</h4>
<p>Nyhedsbrevet leveres med det formål at informere dig om Det Demokratiske Vindmølle Projekt’s nyheder, arrangementer og relaterede oplysninger.</p>

<h4>Afmelding:</h4>
<p>Du kan til enhver tid afmelde dig nyhedsbrevet ved at følge de anvisninger, der er angivet i hver udsendelse, eller ved at kontakte os direkte.</p>

<h4>Fortrolighed:</h4>
<p>Vi respekterer dit privatliv og vil ikke dele dine personlige oplysninger med tredjepart uden din samtykke, medmindre det kræves ved lov.</p>

<h4>Ændringer i Vilkårene:</h4>
<p>Vi forbeholder os retten til at ændre disse brugsbetingelser med eller uden varsel. Det er dit ansvar at holde dig opdateret med eventuelle ændringer.</p>

<h4>Ansvarsfraskrivelse:</h4>
<p>Vi påtager os intet ansvar for eventuelle fejl, mangler eller forsinkelser i indholdet af nyhedsbrevet eller for eventuelle handlinger foretaget baseret på oplysningerne heri.</p>

<h4>Kontakt:</h4>
<p>Har du spørgsmål eller bekymringer vedrørende disse brugsbetingelser, bedes du kontakte os på kontaktinformation.</p>

<h5>Ved at tilmelde dig vores nyhedsbrev bekræfter du, at du har læst og forstået disse brugsbetingelser.</h5>
        </div>
    )
}