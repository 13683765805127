import { AboutProjektInfo } from "./Sites/AboutProjectInfo";
import LocalFiles from "./SmallComponents/LocalFiles";
import "./css/AboutProject.css"


export function AboutProject(){

    return(
<div className="about-project">

        <div className="about-projectInfo">
            <AboutProjektInfo />

    <div className="download-files">
        <LocalFiles />
    </div>
    </div>
    </div>
    )
}