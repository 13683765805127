import React from 'react';
import './css/OmOs.css'; // Create a CSS file for styling

export function OmOs() {
  return (
    <div className="omOs">
      <h3>Personerne bag initiativet</h3>
      <p>
        Vi er 2 landmænd i Salling, Michael Mulvad Madsen og Henrik Henriksen, der prøver at udvikle et vindmølleprojektet.
      </p>
      <p>
        Vi har ikke tidligere arbejdet med mølleprojekter, og har ingen særlige forudsætninger for at arbejdet. Vi har derfor søgt kvalificeret hjælp hos forskellige rådgivere.
      </p>

      <div className="person-images">
        <img src="/Images/henrik.jpg" alt="Person 1" />
        <img src="/Images/michael.jpg" alt="Person 2" />
      </div>
    </div>
  );
}
