import "./css/HeaderImg.css"
export function VideoScreen() {

  return (
    <div>
    <img className="headerImg"
      src="/Images/windmillHome3.jpg"
      alt="Windmill Home"
    />
    </div>
  );
}

/*

    <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: "100vw", // 100% viewport width
        height: "600px", // 100% viewport height
        objectFit: "cover", // Scale video content to cover the entire container
      }}
    >
      <source src="/videos/homescreenWindmill2.mp4" type="video/mp4" />
    </video>

*/
