import "./css/AboutProjectInfo.css"

export function AboutProjektInfo(){

    return(
        <div className="about-projekt">
             <h2>Beskrivelse af vindmølleprojektet</h2>

<p>
  Vi er 2 lokale landmænd Henrik Henriksen (HH) og Michael Mulvad Madsen (MM) der selv udvikler, og vi stiller
  grundene gratis til rådighed. Projektet er demokratisk, hvor et flertal blandt naboerne indenfor 2 km vil afgøre
  hvorvidt projektet kan realiseres eller ikke.
</p>

<p>
Af de 9 huse der måske skal rømmes er der en børnefamilie, med 3 børn.
De resterende 8 huse uden børn har tilsammen 12 beboere med en gennemsnitlig alder på 62 år.
Hvis ejerskab og placeringer går op i en højere enhed, er det muligt for projektansøgerne at blive boende (4
personer), og dermed er der kun 7 beboelser der skal nedlægges.
Det er anført i ansøgningen at det er hensigten at naboerne indenfor 2000 m skal tilbydes ejerskab til den del af
projektet der ligger ud over 1,53 mølle, altså 2,47 mølle eller 4,47 mølle. Der er udsigt til et provenu på hhv. godt
98 mio v. 4 møller eller 178,8 mio. v 6 møller. Beløbet fremkommer på følgende måde: (jf Fjordlands beregninger)
Markedspris på møllerne 110 mio (salgspris)
Anskaffelsespris på møllerne 70 mio (købspris)
Provenu pr. mølle 40 mio (fortjeneste)
</p>

<p>Med udgangspunkt i 6 møllers projektet, som betragtes som det mest realistiske, vil der være 178,8 mio. kr. til
fordeling. De 5 mio. går til foreningerne i nabobyerne Breum, Kirkeby, Thorum og Thise. Det svarer til 10.000 kr.
pr husstand. De resterende 173,8 mio. tilfalder de 116 husstande, der ligger indenfor en afstand af 2 km. Det er 1,5
mio. i gns. pr husstand. Tanken er husstande nærmest møllen skal have mest, og de der vil få mest udsyn fra
boligarealet til møllerne, skal have mere end dem, der ikke har frit udsyn til møllerne fra boligarealet. Fordeling af
godtgørelsen skal foretages af neutrale professionelle. Lovpligtige erstatninger kommer naturligvis oveni
ovenstående beløb.
Det er tanken naboerne frit kan vælge mellem kontant erstatning, køb af anparter, hvor godtgørelsen omregnes til et
antal anparter, man kan købe til kostpris, og endelig en model hvor godtgørelsen omregnes til et antal anparter til
handelspris. Modellerne kan også kombineres såfremt det ønskes.</p>

<h3>Lokal forankring</h3>
<p>Det er hensigten at projektet i videst muligt omfang skal ejes lokalt. Der arbejdes på en samlet kollektiv
finansieringsmodel, som skal muliggøre det for at alle, at eje anparter.
Revisions firmaet Beierholm arbejder i øjeblikket med en model, der sikrer at alle indtægter fra projektet, vil blive
beskattet i Skive kommune.
Der kan blive tale om naboer der ønsker penge, eller ikke kan købe anparter. Disse skal naturligvis også tilgodeses.
For at honorere dette krav, skal der sælges anparter. Disse udbydes først i Skive kommune. Har kommunen
specifikke ønsker på dette område, vil vi bestræbe os på at imødekomme dem i videst muligt omfang. Det er vores
forventning af Co2 certifikaterne fra projektet vil vedblive at være hjemmehørende i Skive kommune. Vi arbejder
på at skaffe dokumentation herfor.
Nedenfor i bilag 2 er det skitseret hvorledes en erstatning på 1,0 mio. kan fordeles.</p>

    <h3>link til kort</h3>
      <p>
        <a href="https://map.krak.dk/m/Dakf8" target="_blank" rel="noopener noreferrer">
          https://map.krak.dk/m/Dakf8
        </a>
      </p>

      <p>Mark nr. 1 er på 10,87 ha. hvoraf 10,09 ha er dyrkningsberettiget
Skoven bliver plantet som vedvarende fredskov. Vi foreslår navnet: Grinderslev skov.
Vi er imødekommende for valg af træarter inden for gældende lovgivning, men foreslår en del hurtigvoksende,
ammetræer, så arealet hurtigt ændrer karakter fra mark til skov. Vi har erfaring med hurtig skovrejsning.
Hurtigvoksende ammetræer binder desuden hurtig en betydende mængde CO2
Senest efter 4 år bliver der anlagt et ubefæstet stisystem på 7,5 km. hvoraf de 1,5 km. er til det ridende publikum.
De resterende 5,7 km anlægges eventyragtig uden lige stræk, som verdens største labyrint.
(Labyrinten på Samsø er godkendt af Guinness World Records ® som verdens største labyrint. Den dækker et areal
på 6 ha. Den samlede stilængde er på 5,4 km. Den besøges af over 25.000 betalende gæster om året.)
Vi stiller labyrinten gratis til rådighed.</p>

<p>Årsagen det først etableres efter 4 år, er for at muliggøre radrensning, da der ikke anvendes kemi eller gødning i
skoven. Publikum er selvfølgelig straks velkommen i Grinderslev skov, og vi håber at stisystemet kan anlægges
allerede efter 2 år.
Der bliver anlagt befæstet P-plads, shelter og bålplads. Publikum får ubegrænset adgang til skoven, også uden for
stierne, inkl. overnatning i telt, shelter eller huler bygget af skovens materialer. Der må plukkes blomster, svampe,
ribs, æbler, kirsebær, hasselnødder og klippes grene til dekoration.
De eneste begrænsninger for publikum, er adfærd der generer andre gæster, forurener eller ødelægger.
Til hundeskov vil vi lave en indhegning på 1 tønde land = 5516 m2 = 1/20 af skoven. Ellers skal hunde føres i snor
af hensyn til andre gæster og faunaen
Beliggenheden er god i forhold til Breum og Grinderslev. Fra Breums lille skov/sportsplads, til Grinderslev skov. er
der kun en 600 meters idyllisk gåtur, hvoraf der kun er 100 meter på asfaltvej, nemlig Rærupvej, hvor trafikken er
behersket.
Hjemturen kan gå 500 meter ad Rærupvej til kirkestien, ad hvilken der er 300 meter til Breum
Fra Grinderslev by til Grinderslev skov, er der 300 meter, ad den trafikerede Grinderslevvej.
En hektar skov binder i gennemsnit ca. 10 tons CO2 pr år, så Grinderslev skov bør efter 3-4 år

binde100 tons CO2 pr år</p>

    <p>Mark nr. 2 er på 3,68 ha. hvoraf det hele er dyrkningsberettiget. De 3,5 ha bliver tilplantet
Skoven bliver plantet som vedvarende fredskov, og vil være skov for bestandigt. En hektar skov binder i
gennemsnit ca. 10 tons CO2 pr år, skovrejsningen på mark 2 bør efter 3-4 år
binde 35 tons CO2 pr år</p>

<p>Mark nr. 3 er på 44,52 ha. hvoraf 36,46 ha er dyrkningsberettiget</p>

<p>marken udlægges til vedvarende tagrør, rørgræs, beplantning, blomster brak, skovrejsning eller andet til forbedring
af biodiversiteten mm. i hele møllernes levetid. Forslagene vil vi gerne kombinere for at skabe yderlige
biodiversitet, og der kommer sikkert flere idéer, men fælles for alle muligheder er at der ikke længere produceres
landbrugsprodukter. Evt. naturpleje i form af afgræsning kan dog ikke udelukkes</p>

<p>Før år 2000 var der en rigtig fin ørredbestand i Rusted Mølle å. For at genskabe den, er vi imødekommende over for
kommunens ønske om omlægning af Rusted Mølle å. Planen er at den atter ledes uden om Brokholm sø, i det gamle
leje, frem til sandfanget og derfra videre i et nyt snoet leje over marken til Hinnerup å. (Vandløbsmedarbejder: Emil
Gammelby Bak er sagsbehandler)</p>

<p>Det 3. tiltag på mark 3 er en vådlægning af 28 ha. kulstofrig lavbundsjord. De 28 ha er systematisk drænet
Vådlægning udføres bl.a. ved at ødelægge dræn og hæve vandstanden. CO2 udledningen reduceres, på den måde,
med 15 t pr. ha. årligt. Det er 420 ton årligt for de 28 ha.
De 28 ha vådlægning sparer også vandmiljøet for ca. 100 kg kvælstof pr. ha. årligt. Det er 2.800 kg kvælstof årligt.
Vådlægningen øger desuden biodiversiteten
(Vandløbsmedarbejder: Emil Gammelby Bak er sagsbehandler)
Af hensyn til den følsomme fauna i området, er det ikke hensigten at etablere stisystemer til publikum i dette
område</p>


<h3>Nedtagningsmøller</h3>
      <p>
        <a href="https://map.krak.dk/m/Dakf8" target="_blank" rel="noopener noreferrer">
          https://map.krak.dk/m/Dakf8
        </a>
      </p>

<p>I det omfang der findes relevante tilskudsordninger, vil vi søge disse for at billiggøre tiltagene. Det er for at sikre
størst muligt provenu til naboerne. Evt. opnåede tilskud til blive beskattet i Skive kommune.</p>

      <p>
        Møllerne er mærket op som 4-5-6 på ovenstående link. Vi er i dialog med ejerne af de 3 vindmøller.
        Nedtagningsmøller handles pt. til høje priser, da ejerne har vetoret. Vi vil gerne købe til almindelig markedspris,
        men de nuværende priser forringer økonomien meget i projektet. Det går ud over det beløb der skal fordeles til
        naboerne. I forvejen bliver der nedtaget 15 møller i området af et andet projekt. Vores egen møllerækken vil forsøges
        placeret så tæt på Fur Landevej som muligt.
      </p>
        </div>
    
    
    )
}