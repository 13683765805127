import React from "react";
import "./css/Footer.css"


export function Footer() {
    const currentYear = new Date();


    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Kontakt os</h3>
            <p>E-mail: info@ddvp.dk</p>
          </div>
  
          <div className="footer-section">
            <h3>Links</h3>
            <ul>
              <li><a href="/">Hjem</a></li>
              <li><a href="/om-projektet">Om projektet</a></li>
              <li><a href="/om-os">personerne bag projektet</a></li>
              <li><a href="/kontakt">Kontakt</a></li>
            </ul>
          </div>
  
          <div className="footer-section">
            <h3>Følg os</h3>
            <div className="social-media-icons">
              <a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a>
            </div>
          </div>
        </div>
  
        <div className="footer-bottom">
        <p>&copy; {currentYear.getFullYear()} Demokratiske Vindmølle Projekt. Alle rettigheder forbeholdes.</p>
          <br/>
        </div>
      </footer>
    );
  }